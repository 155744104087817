<template>
  <SiteTemplate>
    <Header :rota="'Meus Pedidos'" :subrota="'Detalhes do pedido'" />
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div :class="{'gridPedidos':!isMobile, 'gridPedidosMobile':isMobile}" v-if="!loading">
      <Menu :dados="dados" :isMobile="isMobile" />
      <Detalhes :dados="dados" :isMobile="isMobile" />
    </div>
  </SiteTemplate>
</template>

<script>
import SiteTemplate from "@/templates/SiteTemplate.vue";
import Header from "@/components/base/Header.vue";
import Menu from "@/components/Menu.vue";
import Detalhes from "@/components/Detalhes.vue";
import OrderService from "@/services/resources/OrderService";
const service = OrderService.build();

export default {
  name: "Pedidos",
  components: {
    SiteTemplate,
    Header,
    Menu,
    Detalhes,
  },
  data() {
    return {
      client: {
        width: 0,
      },
      loading:false,
      dados: [],
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    fetchOrder() {
      this.loading = true;
      service
        .read(this.$route.params.id)
        .then((e) => {
          console.log(e);
          this.dados = e;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  mounted() {
    this.$root.$on("updateItem", () => {
      this.fetchOrder();
    });
    if(this.$route.params.id){
      this.fetchOrder();
      return
    }
    this.$router.push('/dashboard');
  },
};
</script>